<template>
  <b-modal :active="shown"
    @update:active="onShownChange"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-modal>
    <component :is="component" :data="data" />
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalSketch from '@/components/ModalSketch.vue';
import ModalLogin from '@/components/ModalLogin.vue';

export default {
  name: 'Modal',
  computed: {
    ...mapGetters({
      shown: 'modal/shown',
      name: 'modal/component',
      data: 'modal/data',
    }),
    component() {
      let comp;
      switch (this.name) {
        case 'sketch':
          comp = ModalSketch;
          break;
        case 'login':
          comp = ModalLogin;
          break;
        default:
          comp = null;
      }
      return comp;
    },
  },
  methods: {
    onShownChange(val) {
      if (!val) this.$store.dispatch('modal/hide');
    },
  },
};
</script>
