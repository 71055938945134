<template>
  <div class="sketch-carousel-list">
    <CarouselList
      v-model="clIndex"
      :data="sketches"
      :items-to-show="itemsToShow"
      :repeat="true"
      :arrow="true"
      :arrow-both="true"
      :arrow-hover="true">
      <template slot="item" slot-scope="props">
        <SketchCard :sketch="props.list" :content="false" />
      </template>
    </CarouselList>
  </div>
</template>

<script>
import CarouselList from '@/components/CarouselList.vue';
import SketchCard from '@/components/SketchCard.vue';

export default {
  name: 'SketchCarouselList',
  components: {
    CarouselList,
    SketchCard,
  },
  props: {
    sketches: {
      type: Array,
      required: true,
    },
    itemsToShow: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      clIndex: 0,
    };
  },
};
</script>
