<template>
  <div class="profile container is-fullhd">
    <h1 class="title" v-if="loaded">
      <figure class="image user-avatar">
        <img
          class="is-rounded"
          :src="user.avatar"
          :style="{'border-color': '#' + user.favouriteColour}"
          alt="" />
      </figure>
      <span class="username">{{user.username}}</span>
      <span class="badges">
        <b-tooltip label="Administrator" type="is-dark" position="is-top">
          <span class="icon admin" v-if="user.isAdmin">
            <fa-icon :icon="['fas', 'star']" />
          </span>
        </b-tooltip>
        <b-tooltip label="Plus Member" type="is-dark" position="is-top">
          <span class="icon plus" v-if="user.hasPlus">
            <fa-icon :icon="['fas', 'heart']" />
          </span>
        </b-tooltip>
      </span>
    </h1>

    <section class="columns" v-if="loaded">
      <div class="column is-three-quarters">
        <h2 class="title is-5 stats">
          {{user.experience}} XP, {{user.streak}} day streak
          <Social :userData="user" :compact="true" class="user-social" />
        </h2>

        <h3 class="subtitle">
          <span>Recent sketches</span>
          (<router-link :to="`/gallery/${user.username}`">
            View All
          </router-link>)
        </h3>
        <template v-if="recentSketchesLoaded">
          <template v-if="recentSketches.length">
            <SketchCarouselList :sketches="recentSketches" />
          </template>
          <p v-else>None yet, go sketch something!</p>
        </template>
        <p v-else>Loading...</p>

        <template v-if="user.courses.length">
          <h3 class="subtitle courses-title">
            {{forCurrent ? 'Your courses' : user.username + '\'s courses'}}
          </h3>
          <CourseList :courses="user.courses" :userData="true" />
        </template>
      </div>

      <Sidebar class="column" :user="forCurrent ? null : user" />
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import API from '@/services/api';
import Sidebar from '@/components/Sidebar.vue';
import CourseList from '@/components/CourseList.vue';
import SketchCarouselList from '@/components/SketchCarouselList.vue';
import Social from '@/components/Social.vue';

export default {
  name: 'Profile',
  components: {
    Sidebar,
    CourseList,
    SketchCarouselList,
    Social,
  },
  data() {
    return {
      loaded: false,
      userData: null,

      recentSketches: [],
      recentSketchesLoaded: false,
    };
  },
  created() {
    if (this.forCurrent) {
      this.loaded = true;
      return;
    }

    this.getUser(this.$route.params.username);
  },
  computed: {
    ...mapGetters({
      currentUser: 'session/user',
    }),
    forCurrent() {
      if (!this.currentUser) return false;
      const { username } = this.$route.params;
      return (!username || username === this.currentUser.username);
    },
    user() {
      return this.forCurrent ? this.currentUser : this.userData;
    },
  },
  watch: {
    loaded(val) {
      if (val) this.getRecentSketches();
    },
    user(val) {
      if (val) {
        this.getRecentSketches();
        this.recentSketchesLoaded = false;
      }
    },
  },
  methods: {
    async getUser(name) {
      try {
        const res = await API.users.find(name);
        this.userData = res.data.user;
        this.loaded = true;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
    },
    async getRecentSketches() {
      try {
        const res = await API.sketches.list({ pageSize: 9, userId: this.user.id });
        this.recentSketches = res.data.sketches;
        this.recentSketchesLoaded = true;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-avatar {
  display: inline-block;
  width: 30px;
  height: 30px;

  img {
    border: 2px solid black;
    border-radius: 50%;
  }
}
.username {
  margin-left: 0.2em;
  position: relative;
  bottom: 3px;
}
.badges {
  margin-left: 0.25em;

  .icon {
    font-size: 0.7em;
    margin-right: 5px;
  }
  .admin {
    color: gold;
  }
  .plus {
    color: red;
  }
}
.user-social {
  display: inline;
  font-size: 0.75em;
}
.stats {
  position: relative;
  bottom: 1em;
}
.courses-title {
  margin-top: 2em;
}
</style>
