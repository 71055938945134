<template>
  <div class="media">
    <div class="media-left">
      <figure
        class="image is-32x32 user-avatar is-rounded"
        :style="{'border': '2px solid #' + sketch.author.favouriteColour}">
        <img :src="sketch.author.avatar" alt="" class="is-rounded">
      </figure>
    </div>
    <div class="media-content">
      <p class="title is-5 user-name">
        <router-link
          :to="'/user/' + sketch.author.username"
          >{{sketch.author.username}}</router-link>
        <span class="badges">
          <b-tooltip label="Administrator" type="is-dark" position="is-top">
            <span class="icon is-small admin" v-if="sketch.author.isAdmin">
              <fa-icon :icon="['fas', 'star']" />
            </span>
          </b-tooltip>
          <b-tooltip label="Plus Member" type="is-dark" position="is-top">
            <span class="icon is-small plus" v-if="sketch.author.hasPlus">
              <fa-icon :icon="['fas', 'heart']" />
            </span>
          </b-tooltip>
        </span>
      </p>
      <p class="subtitle is-7">
        {{sketch.author.experience}} XP, {{sketch.author.streak}} day streak
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Author',
  props: {
    sketch: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.user-avatar {
  border-radius: 50%;
}
.user-name a {
  color: black;

  &:hover {
    text-decoration: underline;
  }
}
.badges {
  vertical-align: middle;
  font-size: 0.5em;
  .admin {
    color: gold;
  }
  .plus {
    color: red;
  }
}
</style>
