<template>
  <b-dropdown position="is-bottom-left" @active-change="onToggle" class="notification-bell">
    <a class="navbar-item" slot="trigger" role="button">
      <span class="icon is-large icon-bell" :class="{'has-new': unreadCount}">
        <fa-icon :icon="['fas', 'bell']" />
      </span>
      {{unreadCount}}
    </a>

    <b-dropdown-item
      v-for="n in notifications"
      :key="'notification-' + n.id"
      custom
      paddingless
      class="notification-item"
      :class="{'seen': n.seenAt != null}">

      <div class="box">
        <article class="media">
          <div class="media-left">
            <span class="icon is-large">
              <fa-icon :icon="icon(n)" />
            </span>
          </div>
          <div class="media-content">
            <div class="content">
              <p>
                <template v-if="n.eventName === 'coin-award-streak'">
                  You got {{n.data.coins}} coins for reaching a {{n.data.coins}} streak!
                </template>
                <template v-else-if="n.eventName === 'coin-award-skill'">
                  You got {{n.data.coins}} coins coins for completing the
                  <strong>{{n.data.skill.name}}</strong> skill!
                </template>
                <template v-else-if="n.eventName === 'coin-award-course'">
                  You got {{n.data.coins}} coins coins for completing the
                  <strong>{{n.data.course.name}}</strong> course!
                </template>
                <template v-else-if="n.eventName === 'new-like'">
                  Somebody liked your
                  <router-link :to="'/sketch/' + n.data.sketchId">sketch</router-link>!
                </template>
                <template v-else-if="n.eventName === 'many-likes'">
                  Your
                  <router-link :to="'/sketch/' + n.data.sketchId">sketch</router-link>
                  has reached {{ getLikes(n) }} likes!
                </template>
                <template v-else-if="n.eventName === 'sketch-remixed'">
                  Your
                  <router-link :to="'/sketch/' + n.data.sketchId">sketch</router-link>
                  has been
                  <router-link :to="'/sketch/' + n.data.remixId">remixed</router-link>!
                </template>
                <template v-else>
                  {{ n.eventName }}
                </template>
              </p>
            </div>
            <nav class="level is-mobile">
              <div class="level-left"></div>
              <div class="level-right">
                <div class="level-item notification-date">
                  <small>{{fromNow(n.createdAt)}}</small>
                </div>
              </div>
            </nav>
          </div>
        </article>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from '@/services/dayjs';

export default {
  name: 'NotificationBell',
  computed: {
    ...mapGetters({
      notifications: 'notifications/all',
      unreadCount: 'notifications/unreadCount',
    }),
  },
  methods: {
    async onToggle(visible) {
      if (visible) {
        this.$gtag.event('notification_bell', { event_category: 'engagement' });
      }
      if (!visible || this.unreadCount === 0) return;
      this.$store.dispatch('notifications/markAsRead');
    },
    icon(n) {
      switch (n.eventName) {
        case 'coin-award-streak':
        case 'coin-award-skill':
        case 'coin-award-course':
          return ['fas', 'coins'];
        case 'new-like':
        case 'many-likes':
          return ['fas', 'heart'];
        case 'sketch-remixed':
          return ['fas', 'magic'];
        default:
          break;
      }
      return ['fas', 'bell'];
    },
    getLikes(n) {
      return Number.isNaN(n.data.likes) ? n.data.likes.length : n.data.likes;
    },
    fromNow(d) {
      return dayjs(d).fromNow();
    },
  },
};
</script>

<style lang="scss">
.notification-bell {
  .icon-bell > * {
    font-size: 2em;
  }
  .icon-bell.has-new {
    // color: #004fc4;
    color: #3687ff;
  }

  .dropdown-content {
    padding: 0;
  }

  .notification-item {
    min-width: 300px;
    width: 100%;

    .box {
      border-radius: 0;

      .media-left .icon {
        font-size: 2em;
      }
    }
  }
}
</style>
