<template>
  <b-navbar :spaced="true">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img
          src="@/assets/logo-small.svg"
          alt="SketchDaily"
          class="site-logo"
          width="128"
          height="28"
        >
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item to="/" tag="router-link">
        Home
      </b-navbar-item>
      <b-navbar-item to="/gallery" tag="router-link">
        Gallery
      </b-navbar-item>
      <b-navbar-item to="/leaderboard" tag="router-link">
        Leaderboard
      </b-navbar-item>
      <b-navbar-item to="/profile" tag="router-link" v-if="loggedIn">
        Profile
      </b-navbar-item>
      <b-navbar-item to="/shop" tag="router-link" v-if="loggedIn">
        Shop
      </b-navbar-item>
      <b-navbar-item to="/blog" tag="router-link">
        Blog
      </b-navbar-item>
    </template>

    <template slot="end">
      <template v-if="userLoaded">
        <template v-if="loggedIn">
          <b-tooltip
            :label="userCountTooltip"
            type="is-dark" position="is-bottom">
            <b-navbar-item tag="div">
              <span class="icon is-large icon-users" :class="socketStatus">
                <fa-icon :icon="['fas', 'users']" />
              </span>
              {{userCount}}
            </b-navbar-item>
          </b-tooltip>

          <b-tooltip
            label="Streak"
            type="is-dark" position="is-bottom">
            <b-navbar-item tag="div">
              <span class="icon is-large icon-streak" :class="{'lit': sketchedToday}">
                <fa-icon :icon="['fas', 'fire']" />
              </span>
              {{user.streak}}
            </b-navbar-item>
          </b-tooltip>
          <b-tooltip
            label="Coins"
            type="is-dark" position="is-bottom">
            <b-navbar-item tag="div">
              <span class="icon is-large icon-coins">
                <fa-icon :icon="['fas', 'coins']" />
              </span>
              {{user.coins}}
            </b-navbar-item>
          </b-tooltip>
          <notification-bell />
          <b-navbar-dropdown>
            <template slot="label">
              <img
                :src="user.avatar"
                :style="{'border': '2px solid #' + user.favouriteColour}"
                alt="User settings"
                class="user-avatar is-rounded" />
              <span>{{user.username}}</span>
            </template>
            <b-navbar-item to="/profile" tag="router-link">
              Profile
            </b-navbar-item>
            <b-navbar-item to="/settings" tag="router-link">
              Settings
            </b-navbar-item>
            <b-navbar-item href="javascript:;" @click.stop="logout">
              Log out
            </b-navbar-item>
          </b-navbar-dropdown>
        </template>
        <template v-else>
          <b-navbar-item tag="div">
            <div class="buttons login-buttons">
              <router-link to="/signup" class="button is-primary">
                <strong>Sign up</strong>
              </router-link>
              <a href="#" class="button is-light" @click.prevent="showLogin">
                Log in
              </a>
            </div>
          </b-navbar-item>
        </template>
      </template>
      <template v-else>
        <div class="end-loader">
          <b-skeleton width="200px" height="24px" :animated="true"></b-skeleton>
        </div>
      </template>
    </template>
  </b-navbar>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from '@/services/dayjs';
import NotificationBell from '@/components/NotificationBell.vue';

export default {
  name: 'Navbar',
  components: {
    'notification-bell': NotificationBell,
  },
  computed: {
    ...mapGetters({
      loggedIn: 'session/loggedIn',
      user: 'session/user',
      userLoaded: 'session/loaded',
      userCount: 'socket/userCount',
      socketAuthed: 'socket/authenticated',
    }),
    sketchedToday() {
      return dayjs(this.user.streakUpdatedAt).isAfter(dayjs().startOf('day'));
    },
    socketStatus() {
      if (!this.$socket.connected) return 'connecting';
      if (!this.socketAuthed) return 'authenticating';
      return 'connected';
    },
    userCountTooltip() {
      switch (this.socketStatus) {
        case 'connecting':
          return 'Connecting...';
        case 'authenticating':
          return 'Authenticating...';
        default:
          return 'Amazing artists online';
      }
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch('session/logout');
      if (this.$route.meta && this.$route.meta.auth) await this.$router.push('/');
      this.$toasted.show('Logged out. Hope to see you again soon!', { duration: 1500 });
    },
    showLogin() {
      this.$store.dispatch('modal/show', { name: 'login' });
    },
  },
};
</script>

<style lang="scss" scoped>
.site-logo {
  height: 2em;
  margin-bottom: 5px;
}
.user-avatar {
  margin-right: 0.25em;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  max-height: 32px;
}
.icon-streak.lit {
  color: #FFB03B;
}
.icon-users {
  color: lightgreen;

  &.connecting {
    color: red;
  }
  &.authenticating {
    color: orange;
  }
}
.icon-coins {
  color: gold;
}
.icon-coins > *, .icon-streak > * {
  font-size: 2em;
}
.login-buttons {
  padding: 6px 0;
}
.end-loader {
  padding: 20px 0;
}
.router-link-exact-active {
  text-decoration: underline;
}
</style>
