<template>
  <div class="card">
    <div class="card-image">
      <b-skeleton width="100%" height="238px" size="is-large" :animated="true"></b-skeleton>
    </div>
    <div class="card-content" v-if="content">
      <div class="media">
        <div class="media-left">
          <b-skeleton width="32px" height="32px" size="is-large" :animated="true"></b-skeleton>
        </div>
        <div class="media-content">
          <b-skeleton width="40%" size="is-large" :animated="true"></b-skeleton>
          <b-skeleton width="30%" :animated="true"></b-skeleton>
        </div>
      </div>
      <div class="content">
        <div class="level">
          <div class="level-left">
            <b-skeleton width="90px" height="28px" :animated="true"></b-skeleton>
          </div>
          <div class="level-right">
            <b-skeleton width="100px" :animated="true"></b-skeleton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SketchCardSkeleton',
  props: {
    content: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
