import axios from 'axios';

function buildQueryString(data = {}) {
  const params = [];
  for (const k in data) { // eslint-disable-line
    if (data.hasOwnProperty(k)) { // eslint-disable-line
      params.push(`${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`);
    }
  }
  return `?${params.join('&')}`;
}

const apiURL = process.env.VUE_APP_API_URL;

const API = {
  site: {
    version() {
      return axios.get(`${apiURL}/api/v2/site/version.json`);
    },
    notice() {
      return axios.get(`${apiURL}/api/v2/site/notice.json`);
    },
    contact(data) {
      return axios.post(`${apiURL}/api/v2/site/contact.json`, data);
    },
    social() {
      return axios.get(`${apiURL}/api/v2/site/social.json`);
    },
  },
  auth: {
    login(data) {
      return axios.post(`${apiURL}/api/v2/login.json`, data);
    },
    logout() {
      return axios.delete(`${apiURL}/api/v2/profile/logout.json`);
    },
    signup(data) {
      return axios.post(`${apiURL}/api/v2/register.json`, data);
    },
    resetPassword(data) {
      return axios.post(`${apiURL}/api/v2/password/reset.json`, data);
    },
    setNewPassword(data) {
      return axios.post(`${apiURL}/api/v2/password/set.json`, data);
    },
  },
  users: {
    listTop() {
      return axios.get(`${apiURL}/api/v2/users/top.json?orderBy=experience-desc`);
    },
    get(id) {
      return axios.get(`${apiURL}/api/v2/users/${id}.json`);
    },
    find(username) {
      return axios.get(`${apiURL}/api/v2/users/find/${username}.json`);
    },
    profile() {
      return axios.get(`${apiURL}/api/v2/profile.json`);
    },
    updateSetting(data) {
      return axios.put(`${apiURL}/api/v2/profile/settings.json`, data);
    },
    changePassword(data) {
      return axios.post(`${apiURL}/api/v2/password/change.json`, data);
    },
    social: {
      create(data) {
        return axios.post(`${apiURL}/api/v2/profile/social.json`, data);
      },
      update(id, data) {
        return axios.patch(`${apiURL}/api/v2/profile/social/${id}.json`, data);
      },
      delete(id) {
        return axios.delete(`${apiURL}/api/v2/profile/social/${id}.json`);
      },
    },
  },
  courses: {
    list() {
      return axios.get(`${apiURL}/api/v2/courses.json`);
    },
    get(id) {
      return axios.get(`${apiURL}/api/v2/courses/${id}.json`);
    },
    find(url) {
      return axios.get(`${apiURL}/api/v2/courses/find/${url}.json`);
    },
  },
  skills: {
    get(id) {
      return axios.get(`${apiURL}/api/v2/skills/${id}.json`);
    },
    find(courseUrl, url) {
      return axios.get(`${apiURL}/api/v2/courses/${courseUrl}/skills/${url}.json`);
    },
  },
  lessons: {
    get(id, data = {}) {
      let u = `${apiURL}/api/v2/lessons/${id}.json`;
      u += buildQueryString(data);
      return axios.get(u);
    },
    find(courseUrl, skillUrl, url, data = {}) {
      let u = `${apiURL}/api/v2/courses/${courseUrl}/skills/${skillUrl}/lessons/${url}.json`;
      u += buildQueryString(data);
      return axios.get(u);
    },
    practice(data = {}) {
      return axios.get(`${apiURL}/api/v2/lessons/practice.json${buildQueryString(data)}`);
    },
    trial(id) {
      const data = {};
      if (id > 0) data.id = id;
      return axios.get(`${apiURL}/api/v2/lessons/try.json${buildQueryString(data)}`);
    },
  },
  sketches: {
    list({ page = 1, pageSize = 28, userId = 0 }) {
      let url = `${apiURL}/api/v2/sketches.json?page=${page}&pageSize=${pageSize}`;
      if (userId > 0) url += `&userId=${userId}`;
      return axios.get(url);
    },
    listForUser({ page = 1, pageSize = 28, username }) {
      const data = { page, pageSize };
      const url = `${apiURL}/api/v2/users/find/${username}/sketches.json${buildQueryString(data)}`;
      return axios.get(url);
    },
    listFeatured({ page = 1, pageSize = 28 }) {
      return axios.get(`${apiURL}/api/v2/sketches/featured.json?page=${page}&pageSize=${pageSize}`);
    },
    listTop({ range = 'today', pageSize = 3 }) {
      const data = { range, pageSize };
      return axios.get(`${apiURL}/api/v2/sketches/top.json${buildQueryString(data)}`);
    },
    listForUserLesson(lessonId) {
      return axios.get(`${apiURL}/api/v2/lessons/${lessonId}/sketches.json`);
    },
    get(id) {
      return axios.get(`${apiURL}/api/v2/sketches/${id}.json`);
    },
    like(id) {
      return axios.put(`${apiURL}/api/v2/sketches/${id}/like.json`);
    },
    save(data) {
      return axios.post(`${apiURL}/api/v2/sketches.json`, data);
    },
    saveTrial(data) {
      return axios.post(`${apiURL}/api/v2/sketches/trial.json`, data);
    },
  },
  notifications: {
    list() {
      return axios.get(`${apiURL}/api/v2/notifications.json`);
    },
    read(date) {
      return axios.put(`${apiURL}/api/v2/notifications/read.json`, { date });
    },
  },
  shop: {
    buyStreakFreeze() {
      return axios.put(`${apiURL}/api/v2/shop/streak-freeze.json`);
    },
  },
  blog: {
    articles: {
      list(data = {}) {
        return axios.get(`${apiURL}/api/v2/blog/articles.json${buildQueryString(data)}`);
      },
      get(id) {
        return axios.get(`${apiURL}/api/v2/blog/articles/${id}.json`);
      },
      getBySlug(slug) {
        return axios.get(`${apiURL}/api/v2/blog/articles/find/${slug}.json`);
      },
    },
  },
  billing: {
    get() {
      return axios.get(`${apiURL}/api/v2/billing.json`);
    },
    subscribe(data) {
      return axios.post(`${apiURL}/api/v2/billing/subscribe.json`, data);
    },
    portal() {
      return axios.get(`${apiURL}/api/v2/billing/portal.json`);
    },
  },
  admin: {
    sketches: {
      feature(id) {
        return axios.put(`${apiURL}/api/v2/sketches/${id}/feature.json`);
      },
      unfeature(id) {
        return axios.put(`${apiURL}/api/v2/sketches/${id}/unfeature.json`);
      },
      delete(id) {
        return axios.put(`${apiURL}/api/v2/sketches/${id}.json`);
      },
    },
    site: {
      getStats() {
        return axios.get(`${apiURL}/api/v2/admin/stats.json`);
      },
      getMaintenance() {
        return axios.get(`${apiURL}/api/v2/admin/maintenance.json`);
      },
      updateMaintenance(data) {
        return axios.patch(`${apiURL}/api/v2/admin/maintenance.json`, data);
      },
      updateNotice(data) {
        return axios.patch(`${apiURL}/api/v2/admin/notice.json`, data);
      },
      resetCache(data) {
        return axios.put(`${apiURL}/api/v2/admin/reset-cache.json`, data);
      },
    },
    blog: {
      articles: {
        list(data = {}) {
          return axios.get(`${apiURL}/api/v2/blog/admin/articles.json${buildQueryString(data)}`);
        },
        create(data) {
          return axios.post(`${apiURL}/api/v2/blog/articles.json`, data);
        },
        update(id, data) {
          return axios.patch(`${apiURL}/api/v2/blog/articles/${id}.json`, data);
        },
        publish(id) {
          return axios.put(`${apiURL}/api/v2/blog/articles/${id}/publish.json`);
        },
        unpublish(id) {
          return axios.put(`${apiURL}/api/v2/blog/articles/${id}/unpublish.json`);
        },
      },
      upload(files) {
        const formData = new FormData();
        formData.append('file', files);
        return axios.post(`${apiURL}/api/v2/blog/upload.json`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      },
    },
  },

  handleError(e) {
    if (!e) return null;

    const err = {
      code: 'UKAPIERR',
      message: 'An unknown error has occurred.',
      fields: [],
      status: 500,
    };

    if (e.response) { // got non-2xx response
      if (e.response.status) err.status = e.response.status;
      if (e.response.data.code) err.code = e.response.data.code;
      if (e.response.data.message) {
        err.message = e.response.data.message;
        if (err.message.indexOf('\n') !== -1) {
          const messages = err.message.split('\n');
          messages.forEach((m) => {
            if (!m || m.length === 0) return;

            const parts = m.split(':');
            if (parts.length < 2) return;

            let message = parts.splice(1).join('').trim();
            message = message[0].toUpperCase() + message.substr(1);
            const f = {
              field: parts[0].trim(),
              message,
            };

            err.fields.push(f);
          });
        }
      }
    } else {
      console.error(e);
    }

    return err;
  },
};

export default API;
