<template>
  <router-link :to="'/course/' + course.url" :title="course.description">
    <div class="card course-card"
      :class="{'is-complete': course.progress >= 100}">
      <div class="card-image">
        <figure class="image is-square">
          <img :src="course.image" :alt="course.name">
        </figure>
      </div>
      <div class="card-content">
        <div class="content">
          <p class="has-text-weight-semibold has-text-centered">{{course.name}}</p>
          <template v-if="userData">
            <p v-if="course.progress >= 100" class="has-text-centered complete">
              <span class="icon has-text-success">
                <fa-icon :icon="['fas', 'check-circle']" />
              </span>
              <span class="complete-text">Complete</span>
            </p>
            <b-progress
              v-else
              type="is-success"
              :value="course.progress"
              :show-value="true"
              format="percent"
              :precision="0"
              :keep-trailing-zeroes="true" />
            <div class="level">
              <div class="level-left">
                <small>{{experience}} XP</small>
              </div>
              <div class="level-right">
                <small>Joined {{joinedAgo}}</small>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import dayjs from '@/services/dayjs';

export default {
  name: 'CourseCard',
  props: {
    course: {
      type: Object,
      required: true,
    },
    userData: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    joinedAgo() {
      if (!this.userData) return null;
      const ca = dayjs(this.course.dateJoined);
      const m = dayjs();
      return ca.isAfter(m) ? m.fromNow() : ca.fromNow();
    },
    experience() {
      return this.course.experience ? this.course.experience : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.card.is-complete {
  background: rgba(100, 255, 100, 0.1);
}
.complete {
  line-height: 1;

  .complete-text {
    position: relative;
    bottom: 0.3em;
  }
}
</style>
