<template>
  <div class="home container is-fullhd">
    <template v-if="loggedIn">
      <h1 class="title">Home</h1>
      <section class="columns">
        <div class="column is-three-quarters">
          <template v-if="user.courses.length">
            <h2 class="subtitle">Your courses</h2>
            <CourseList :courses="user.courses" :userData="true" />
          </template>

          <h2 class="subtitle">Featured sketches</h2>
          <SketchCarouselList :sketches="featuredSketches" />

          <h2 class="subtitle courses-title">Courses</h2>
          <CourseList :courses="courses" :userData="true" />
        </div>

        <Sidebar class="column" v-if="loggedIn" />
      </section>
    </template>
    <template v-else> <!-- logged out content -->
      <section class="columns">
        <div class="column is-half">
          <h1 class="title is-1 has-text-weight-light">
            Learn and practice drawing online, for free.
          </h1>
          <ul class="features mb-5">
            <li>Express your creativity</li>
            <li>Improve your drawings skills</li>
            <li>Explore and share others' sketches</li>
          </ul>
          <div class="tagline">
            <router-link to="/signup" class="button is-primary">
              Get Started
            </router-link>
            or <a href="#" @click.prevent="showLogin">log in</a>
          </div>
        </div>
        <div class="column is-half">
          <div class="slideshow" ref="slideshow">
            <img
              v-for="(sketch, i) in featuredSketches"
              :key="i"
              :src="getImageSrc(sketch.id)"
              :srcset="getImageSrcSet(sketch.id)"
              :alt="sketch.lesson.name + ' by ' + sketch.author.username"
              :title="sketch.lesson.name + ' by ' + sketch.author.username"
              @click.stop="expandSketch(sketch)"
              width="660"
              height="330"/>
          </div>
        </div>
      </section>
      <section>
        <div class="hero">
          <div class="hero-body">
            <div class="container">
              <h2 class="title">Choose your own path</h2>
              <h3 class="subtitle">
                Complete courses, skills and lessons in any order –
                complete freedom of choice for your own progress.
              </h3>
            </div>
          </div>
        </div>
        <div class="hero has-text-right">
          <div class="hero-body">
            <div class="container">
              <h2 class="title">Create and share</h2>
              <h3 class="subtitle">
                Share your own and explore others' creativity –
                all sketches are in the public domain.
              </h3>
            </div>
          </div>
        </div>
        <div class="hero">
          <div class="hero-body">
            <div class="container">
              <h2 class="title">Stay motivated</h2>
              <h3 class="subtitle">
                Stay motivated by receiving experience, coins and likes
                from your fellow artists. Like a sketch? Share the ❤️ love.
              </h3>
              <p class="is-6 mb-2">Check out some of the most liked sketches this month</p>
              <SketchCarouselList :sketches="topSketches" v-if="topSketches.length" />
              <b-skeleton width="100%" height="313" :animated="true" v-else/>
            </div>
          </div>
        </div>
        <div class="hero has-text-right">
          <div class="hero-body">
            <div class="container">
              <h2 class="title">Keep on track</h2>
              <h3 class="subtitle">
                Never skip a day – receive daily reminders to keep your streak going,
                or take it at your own pace and turn the reminders off.
              </h3>
            </div>
          </div>
        </div>
        <div class="hero">
          <div class="hero-body">
            <div class="container">
              <h2 class="title">Get seen</h2>
              <h3 class="subtitle">
                Want a little bit of competion? Push your way up to the leaderboard as you gain XP.
                Want to take it easy? Take your time, add the extra detail or put a unique twist –
                your sketch may be featured on this very page or our social media!
              </h3>
            </div>
          </div>
        </div>
        <div class="hero has-text-right">
          <div class="hero-body">
            <div class="container">
              <h2 class="title">Go Plus</h2>
              <h3 class="subtitle">
                Take your sketching to the next level by downloading SVG
                versions and support SketchDaily with a Plus Membership.
              </h3>
            </div>
          </div>
        </div>
        <div class="hero has-text-centered is-primary hero-signup">
          <div class="hero-body">
            <div class="container">
              <h2 class="title">What are you waiting for?</h2>
              <router-link to="/signup" class="button is-dark is-outlined is-large">
                Start your first sketch
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import API from '@/services/api';
import Sidebar from '@/components/Sidebar.vue';
import CourseList from '@/components/CourseList.vue';
import SketchCarouselList from '@/components/SketchCarouselList.vue';

let slideshowStart;

export default {
  name: 'Home',
  components: {
    Sidebar,
    CourseList,
    SketchCarouselList,
  },
  data() {
    return {
      featuredSketches: [],
      topSketches: [],
    };
  },
  created() {
    this.getFeatured();
    this.getTop();
  },
  mounted() {
    if (this.loggedIn) return;
    window.requestAnimationFrame(this.scrollSlideshow);
  },
  watch: {
    loggedIn(val) {
      if (!val) window.requestAnimationFrame(this.scrollSlideshow);
    },
  },
  computed: {
    ...mapGetters({
      loggedIn: 'session/loggedIn',
      user: 'session/user',
      courses: 'site/courses',
    }),
  },
  methods: {
    async getFeatured() {
      try {
        const { data } = await API.sketches.listFeatured({ pageSize: 18 });
        this.featuredSketches = data.sketches;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
    },
    async getTop() {
      try {
        const { data } = await API.sketches.listTop({ pageSize: 9, range: 'month' });
        this.topSketches = data.sketches;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
    },
    scrollSlideshow(ts) {
      if (slideshowStart === null) slideshowStart = ts;
      const elapsed = ts - slideshowStart;

      const el = this.$refs.slideshow;
      if (!el) return; // cancel if el is gone

      if (el.scrollTop >= el.scrollHeight - el.offsetHeight) {
        el.scrollTop = 0;
        slideshowStart = ts; // reset start
      } else {
        el.scrollTop = 0.1 * elapsed;
      }
      window.requestAnimationFrame(this.scrollSlideshow);
    },
    expandSketch(sketch) {
      this.$store.dispatch('modal/show', {
        name: 'sketch',
        data: { sketch },
      });
    },
    showLogin() {
      this.$store.dispatch('modal/show', { name: 'login' });
    },
    getImageSrc(id) {
      return `/api/v2/sketches/${id}/thumbnail.png?width=330`;
    },
    getImageSrcSet(id) {
      return `/api/v2/sketches/${id}/thumbnail.png?width=330 330w,
              /api/v2/sketches/${id}/thumbnail.png?width=660 660w,
              /api/v2/sketches/${id}/thumbnail.png?width=990 990w,
              /api/v2/sketches/${id}/thumbnail.png?width=1320 1320w`;
    },
  },
};
</script>

<style lang="scss" scoped>
.courses-title {
  margin-top: 1em;
}
.tagline {
  line-height: 2.25em;
}
.slideshow {
  height: 330px;
  overflow: hidden;

  img {
    cursor: pointer;
    width: 100%;
    height: auto;
  }
}
</style>
