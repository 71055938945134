<template>
  <div class="cookie-notice">
    <div class="level">
      <div class="level-left">
        <small>
          By visiting SketchDaily you are consenting to our use of cookies and
          accepting our
          <router-link class="link" to="/terms">Terms</router-link>,
          <router-link class="link" to="/privacy">Privacy Policy</router-link>,
          and
          <router-link class="link" to="/cookies">Cookie Policy</router-link>.
        </small>
      </div>
      <div class="level-right">
        <b-button class="ok" type="is-primary" @click="dismiss">OK</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookieNotice',
  methods: {
    dismiss() {
      this.$store.dispatch('site/hideCookieNotice');
    },
  },
};
</script>

<style lang="scss" scoped>
.cookie-notice {
  position: fixed;
  text-align: center;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  background: #333;
  color: white;
  z-index: 1000001; // above Crisp
  padding: 0.5em 1em;
}
.link {
  color: #FFB03B;
}
.ok {
  display: inline-block;

}
</style>
