import io from 'socket.io-client';

const config = {
  autoConnect: false,
  timeout: 10000,
  reconnection: true,
  reconnectionAttempts: 100,
  reconnectionDelay: 500,
  reconnectionDelayMax: 2000,
  forceNew: true,
  transports: ['websocket'],
};

const socket = {
  init() {
    return io(process.env.VUE_APP_SOCKET_URL, config);
  },
};

export default socket;
