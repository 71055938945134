import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Home from '../views/Home.vue';
import Gallery from '../views/Gallery.vue';
import Profile from '../views/Profile.vue';
import Sketch from '../views/Sketch.vue';
import NotFound from '../views/NotFound.vue';

Vue.use(VueRouter);

const baseTitle = 'SketchDaily - Improve Your Drawing Skills';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: baseTitle,
      hasSidebar: true,
    },
  },
  {
    path: '/gallery/:username?',
    name: 'Gallery',
    component: Gallery,
    meta: {
      title: `Gallery - ${baseTitle}`,
    },
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: () => import(/* webpackChunkName: "leaderboard" */ '../views/Leaderboard.vue'),
    meta: {
      title: `Leaderboard - ${baseTitle}`,
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      title: `Profile - ${baseTitle}`,
      auth: true,
    },
  },
  {
    path: '/user/:username',
    name: 'User Profile',
    component: Profile,
    meta: {
      title: `User Profile - ${baseTitle}`,
    },
  },
  {
    path: '/sketch/:sketchId',
    name: 'Sketch',
    component: Sketch,
    meta: {
      title: `Sketch - ${baseTitle}`,
    },
  },
  {
    path: '/practice',
    name: 'Practice',
    component: () => import(/* webpackChunkName: "lesson" */ '../views/Practice.vue'),
    meta: {
      title: `Practice - ${baseTitle}`,
      auth: true,
    },
  },
  {
    path: '/signup',
    name: 'Sign Up',
    component: () => import(/* webpackChunkName: "lesson" */ '../views/Signup.vue'),
    meta: {
      title: `Sign Up - ${baseTitle}`,
    },
  },
  {
    path: '/course/:courseUrl',
    name: 'Course',
    component: () => import(/* webpackChunkName: "course" */ '../views/Course.vue'),
    meta: {
      title: `Course - ${baseTitle}`,
      auth: true,
    },
  },
  {
    path: '/course/:courseUrl/:skillUrl',
    name: 'Skill',
    component: () => import(/* webpackChunkName: "skill" */ '../views/Skill.vue'),
    meta: {
      title: `Skill - ${baseTitle}`,
      auth: true,
    },
  },
  {
    path: '/course/:courseUrl/:skillUrl/:lessonUrl',
    name: 'Lesson',
    component: () => import(/* webpackChunkName: "lesson" */ '../views/Lesson.vue'),
    meta: {
      title: `Lesson - ${baseTitle}`,
      auth: true,
    },
  },
  {
    path: '/lessons/:lessonId',
    name: 'Lesson',
    component: () => import(/* webpackChunkName: "lesson" */ '../views/Lesson.vue'),
    meta: {
      title: `Lesson - ${baseTitle}`,
      auth: true,
    },
  },
  {
    path: '/skills/:skillId',
    name: 'Skill',
    component: () => import(/* webpackChunkName: "skill" */ '../views/Skill.vue'),
    meta: {
      title: `Skill - ${baseTitle}`,
      auth: true,
    },
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import(/* webpackChunkName: "shop" */ '../views/Shop.vue'),
    meta: {
      title: `Shop - ${baseTitle}`,
      auth: true,
    },
  },
  {
    path: '/shop/success',
    name: 'Shop',
    component: () => import(/* webpackChunkName: "shop" */ '../views/ShopSuccess.vue'),
    meta: {
      title: `Shop - ${baseTitle}`,
      auth: true,
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: {
      title: `Settings - ${baseTitle}`,
      auth: true,
    },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (static.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "static" */ '../views/About.vue'),
    meta: {
      title: `About - ${baseTitle}`,
    },
  },
  {
    path: '/contact',
    name: 'Contact Us',
    component: () => import(/* webpackChunkName: "static" */ '../views/Contact.vue'),
    meta: {
      title: `Contact Us - ${baseTitle}`,
    },
  },
  {
    path: '/terms',
    name: 'Terms & Conditions',
    component: () => import(/* webpackChunkName: "static" */ '../views/Terms.vue'),
    meta: {
      title: `Terms & Conditions - ${baseTitle}`,
    },
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    component: () => import(/* webpackChunkName: "static" */ '../views/Privacy.vue'),
    meta: {
      title: `Privacy Policy - ${baseTitle}`,
    },
  },
  {
    path: '/cookies',
    name: 'Cookie Policy',
    component: () => import(/* webpackChunkName: "static" */ '../views/Cookies.vue'),
    meta: {
      title: `Cookie Policy - ${baseTitle}`,
    },
  },
  {
    path: '/open-source',
    name: 'Open Source',
    component: () => import(/* webpackChunkName: "static" */ '../views/OpenSource.vue'),
    meta: {
      title: `Open Source - ${baseTitle}`,
    },
  },
  {
    path: '/reset-password/:userId/:resetCode',
    name: 'Reset Password',
    component: () => import(/* webpackChunkName: "static" */ '../views/ResetPassword.vue'),
    meta: {
      title: `Set A New Password - ${baseTitle}`,
    },
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import(/* webpackChunkName: "content" */ '../views/Blog.vue'),
    meta: {
      title: `Blog - ${baseTitle}`,
    },
  },
  {
    path: '/blog/:slug',
    name: 'Article',
    component: () => import(/* webpackChunkName: "content" */ '../views/Article.vue'),
    meta: {
      title: `Blog - ${baseTitle}`,
    },
  },
  {
    path: '/admin/:tab?',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "content" */ '../views/Admin.vue'),
    meta: {
      title: `Admin - ${baseTitle}`,
      auth: true,
      admin: true,
    },
  },
  {
    path: '/editor/:id?',
    name: 'Editor',
    component: () => import(/* webpackChunkName: "content" */ '../views/Editor.vue'),
    meta: {
      title: `Editor - ${baseTitle}`,
      auth: true,
      admin: true,
    },
  },
  {
    path: '/404',
    component: NotFound,
    meta: {
      title: `Not Found - ${baseTitle}`,
    },
  },
  { path: '*', redirect: '/404' },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const pv = store.state.site.preventNavigation;
  if (pv.prevent) {
    const leave = window.confirm(pv.text); // eslint-disable-line no-alert
    if (!leave) return;
  }
  store.dispatch('site/preventNavigation', false);

  // v1 redirect
  if (to.fullPath.substr(0, 2) === '/#') {
    const path = to.fullPath.substr(2);
    next(path);
    return;
  }

  // admin check
  if (to.meta.admin && !store.getters['session/isAdmin']) {
    next('/404');
    return;
  }

  document.title = to.meta.title;
  store.dispatch('modal/hide');
  next();
});

router.afterEach(() => {
  if (window.goatcounter) {
    window.goatcounter.count();
  }
});

export default router;
