<template>
  <div class="xp-chart">
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>
import {
  Chart, LineController, LineElement, PointElement, LinearScale, CategoryScale, Filler,
} from 'chart.js';
import dayjs from '@/services/dayjs';

Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Filler);

export default {
  name: 'Sidebar',
  props: {
    xp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    const ctx = this.$refs.chart.getContext('2d');
    const suggestedMax = this.getDisplayedMax(this.preparedXP);
    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.getLabels(),
        datasets: [{
          label: 'XP',
          data: this.preparedXP,
          pointBackgroundColor: this.getColors(),
          pointBorderColor: 'rgb(0, 166, 204)',
          borderColor: 'rgba(0, 166, 204, 0.7)',
          backgroundColor: 'rgba(0, 217, 255, 0.3)',
          borderWidth: 2,
          lineTension: 0,
          fill: 'origin',
        }],
      },
      options: {
        legend: { display: false },
        scales: {
          x: {
            grid: { display: false },
          },
          y: {
            beginAtZero: true,
            suggestedMax,
            ticks: {
              stepSize: suggestedMax >= 50 ? 10 : 5,
            },
          },
        },
      },
    });
  },
  computed: {
    preparedXP() {
      const days = [0, 0, 0, 0, 0, 0, 0];
      Object.keys(this.xp).forEach((day) => {
        for (let i = 0; i <= 6; i += 1) {
          const f = dayjs().add(i * -1, 'day').format('YYYY-MM-DD');
          if (day === f) days[days.length - i - 1] = this.xp[day];
        }
      });
      return days;
    },
  },
  watch: {
    preparedXP(data) {
      if (data.length !== this.chart.data.datasets[0].data.length) return;
      Object.values(data).forEach((val, i) => {
        this.chart.data.datasets[0].data[i] = val;
      });
      this.chart.data.datasets[0].pointBackgroundColor = this.getColors();
      const suggestedMax = this.getDisplayedMax(data);
      const stepSize = suggestedMax >= 50 ? 10 : 5;
      this.chart.options.scales.y.suggestedMax = suggestedMax;
      this.chart.options.scales.y.ticks.stepSize = stepSize;
      this.chart.update();
    },
  },
  methods: {
    getLabels() {
      const d = dayjs();
      const labels = [d.format('ddd')];
      for (let i = 1; i <= 6; i += 1) {
        labels.unshift(d.subtract(i, 'd').format('ddd'));
      }
      return labels;
    },
    getColors() {
      const arr = [];
      this.preparedXP.forEach((i) => {
        let color = '#fff';
        if (i > 0) color = 'rgba(0, 166, 204, 1)';
        arr.push(color);
      });
      return arr;
    },
    getDisplayedMax(data) {
      let max = 10;
      data.forEach((d) => {
        const m = d + 10;
        if (m > max) max = m;
      });
      return max;
    },
  },
};
</script>
