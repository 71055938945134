import Vue from 'vue';
import API from '@/services/api';

export default {
  namespaced: true,
  state: {
    all: [],
  },
  getters: {
    all: (state) => state.all,
    unread: (state) => state.all.filter((n) => !n.seenAt),
    unreadCount: (state) => state.all.filter((n) => !n.seenAt).length,
  },
  mutations: {
    setNotifications(state, notifications) {
      state.all = notifications;
    },
    markAsRead(state, latestDate) {
      state.all.forEach((n) => {
        if (!n.seenAt) n.seenAt = latestDate; // eslint-disable-line no-param-reassign
      });
    },
  },
  actions: {
    async load({ commit }) {
      try {
        const res = await API.notifications.list();
        res.data.notifications.forEach((n) => {
          n.data = JSON.parse(n.args); // eslint-disable-line no-param-reassign
        });
        commit('setNotifications', res.data.notifications);
      } catch (e) {
        const error = API.handleError(e);
        if (error) {
          const msg = `Failed to load notifications: ${error.message}`;
          Vue.toasted.error(msg, { duration: 2000 });
        }
      }
    },
    async markAsRead({ commit, state }) {
      try {
        const latestDate = state.all[0].createdAt;
        await API.notifications.read(latestDate);
        commit('markAsRead', latestDate);
      } catch (e) {
        console.log('markAsRead error', e);
      }
    },
  },
};
