<template>
  <div class="social-icons">
    <div
      v-for="(social, i) in user.social"
      :key="i"
      class="social"
      :class="{inline: inline}">
      <b-tooltip :label="socialById(social.socialId).name" type="is-dark">
        <a
          :href="socialURL(social)"
          target="_blank"
          rel="nofollow">
          <span class="icon" :style="{color: `#${socialById(social.socialId).color}`}">
            <fa-icon :icon="[
              socialById(social.socialId).iconPack,
              socialById(social.socialId).icon,
            ]" />
          </span>
          <span v-if="!compact" class="social-content">{{social.content}}</span>
        </a>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Social',
  props: {
    userData: {
      type: Object,
      default: null,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'session/user',
      socialById: 'site/socialById',
    }),
    user() {
      if (this.userData !== null) return this.userData;
      return this.currentUser;
    },
  },
  methods: {
    socialURL(social) {
      const sd = this.socialById(social.socialId);
      const prefix = sd.urlPrefix ? sd.urlPrefix : '';
      return `${prefix}${social.content}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.social {
  &.inline {
    display: inline-block;
  }
}
.social-content {
  position: relative;
  bottom: 2px;
}
</style>
