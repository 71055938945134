import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import API from '@/services/api';

export default {
  namespaced: true,
  state: {
    loaded: false,

    user: null,
    loggedIn: false,
  },
  getters: {
    loaded: (state) => state.loaded,
    user: (state) => state.user,
    loggedIn: (state) => state.loggedIn,
    isAdmin: (state) => state.user ? state.user.isAdmin : false, // eslint-disable-line
  },
  mutations: {
    loaded(state) {
      state.loaded = true;
    },
    login(state, user) {
      state.user = user;
      state.loggedIn = true;
      Sentry.setUser({
        id: user.id,
        email: user.email,
      });
      if (window.$crisp) {
        window.$crisp.push(['set', 'user:email', [user.email]]);
        window.$crisp.push(['do', 'chat:hide']);
      }
    },
    logout(state) {
      state.loggedIn = false;
      state.user = null;
      Sentry.configureScope((scope) => scope.clear());
    },
    updateUserProperty(state, { name, value }) {
      state.user[name] = value;
    },
  },
  actions: {
    async getProfile({ commit, dispatch }) {
      try {
        const res = await API.users.profile();
        commit('login', res.data.user);
        dispatch('notifications/load', {}, { root: true });
        dispatch('site/getCourses', {}, { root: true });
      } catch (e) {
        // alert on non 401?
        const error = API.handleError(e);
        if (error && error.status !== 401) {
          Vue.toasted.error(error.message, { duration: 2000 });
        }
      }
      commit('loaded');
    },
    async logout({ commit }) {
      try {
        await API.auth.logout();
        commit('logout');
      } catch (e) {
        console.log('logout error', e);
      }
    },
  },
};
