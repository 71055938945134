<template>
  <div class="card">
    <div class="card-header">
      <p class="card-header-title">Log in</p>
    </div>
    <div class="card-content">
      <LoginForm
        v-if="login"
        @close="$parent.close()"
        @reset="login = false"
        class="content" />
      <ResetForm
        v-else
        @close="$parent.close()"
        @login="login = true"
        class="content" />
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue';
import ResetForm from '@/components/ResetForm.vue';

export default {
  name: 'ModalLogin',
  components: {
    LoginForm,
    ResetForm,
  },
  data() {
    return {
      login: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 350px;
}
</style>
