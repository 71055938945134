<template>
  <div class="card sketch-card">
    <div class="card-image" @click.stop="expandSketch">
      <figure class="image is-4by3">
        <img
          :src="thumbURL"
          :alt="sketch.lesson.name"
          :srcset="thumbURLSet">
      </figure>
    </div>
    <div class="card-content" v-if="content">

      <Author :sketch="sketch" />

      <div class="content">
        <Actions :sketch="sketch" />
      </div>
    </div>
  </div>
</template>

<script>
import Author from '@/components/Sketch/Author.vue';
import Actions from '@/components/Sketch/Actions.vue';

export default {
  name: 'SketchCard',
  components: {
    Author,
    Actions,
  },
  props: {
    sketch: {
      type: Object,
      required: true,
    },
    content: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    thumbURL() {
      return this.getThumbURL(300);
    },
    thumbURLSet() {
      let srcset = `${this.getThumbURL(300)} 300w`;
      srcset += `, ${this.getThumbURL(450)} 450w`;
      srcset += `, ${this.getThumbURL(600)} 600w`;
      return srcset;
    },
  },
  methods: {
    expandSketch() {
      this.$store.dispatch('modal/show', {
        name: 'sketch',
        data: { sketch: this.sketch },
      });
    },
    getThumbURL(width) {
      return `/api/v2/sketches/${this.sketch.id}/thumbnail.png?width=${width}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-image {
  cursor: pointer;
}
.card-content {
  box-shadow: inset 0px 5px 5px -5px #ccc;
}
.image.is-4by3 img { //override default
  height: auto;
  margin: auto 0;
  max-height: 100%;
}
</style>
