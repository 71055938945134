<template>
  <div class="sketch-page container is-fullhd">
    <h1 class="title">
      <span class="sketch-title">{{title}}</span>
      <template v-if="!loading">
        by
        <figure
          class="image is-32x32 user-avatar is-rounded"
          :style="{'border': '2px solid #' + sketch.author.favouriteColour}">
          <img :src="sketch.author.avatar" alt="" class="is-rounded">
        </figure>
        <router-link
          :to="'/user/' + sketch.author.username"
          class="user-name">
          {{sketch.author.username}}
        </router-link>
      </template>
    </h1>
    <h2 class="subtitle">{{createdAt}}</h2>
    <section v-if="sketch">
      <div class="sketch">
        <img :src="sketch.imageUrl" :alt="sketch.lesson.name" />
      </div>
      <Actions :sketch="sketch" :expanded="true" :labelled="true" />
    </section>
  </div>
</template>

<script>
import API from '@/services/api';
import dayjs from '@/services/dayjs';
import Actions from '@/components/Sketch/Actions.vue';

export default {
  name: 'Sketch',
  components: {
    Actions,
  },
  data() {
    return {
      loading: true,
      sketch: null,
    };
  },
  created() {
    this.load(this.$route.params.sketchId);
    this.$gtag.event('view_item');
  },
  computed: {
    title() {
      if (this.loading) return 'Sketch';
      return this.sketch.lesson.name;
    },
    createdAt() {
      if (this.loading) return 'Loading...';

      const prefixes = [
        'Beamed from an alien spaceship',
        'Born',
        'Cobbled together',
        'Digitised from an ancient manuscript',
        'Discovered at an abandoned manor',
        'Forged in the flames of Mauna Loa',
        'Found in a treasure chest',
        'Retrieved from the summit of Mt. Kilimanjaro',
        'Sketched',
        'Skillfully crafted',
        'Splashed onto the canvas',
        'Summoned into our realm',
        'Teleported in from another dimension',
        'Willed into existence',
      ];
      const prefix = prefixes[Math.floor(Math.random() * prefixes.length)];

      const ca = dayjs(this.sketch.createdAt);
      return `${prefix} at ${ca.format('HH:mm')}, on the ${ca.format('Do MMMM YYYY')}`;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.params.sketchId': function (sketchId) {
      this.load(sketchId);
    },
  },
  methods: {
    load(sketchId) {
      this.loading = true;
      this.sketch = null;
      this.getSketch(sketchId);
    },
    async getSketch(id) {
      try {
        const res = await API.sketches.get(id);
        this.sketch = res.data.sketch;
        this.loading = false;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sketch-title {
  font-style: italic;
}
.sketch {
  text-align: center;
  img {
    border: 1px solid #ccc;
  }
}
.user-avatar {
  display: inline-block;
  border-radius: 50%;
  position: relative;
  top: 4px;
}
</style>
