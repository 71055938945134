<template>
  <form @submit="login" class="login-form">
    <b-field
      label="Email"
      :message="getError('email')"
      :type="{'is-danger': $v.email.$error}">
      <b-input
        v-model="email"
        type="email"
        autocomplete="email"
        ref="email" />
    </b-field>
    <b-field
      :message="getError('password')"
      :type="{'is-danger': $v.password.$error}">
      <template #label>
        <div class="level">
          <div class="level-left">Password</div>
          <div class="level-right">
            <a
              href="javascript:;"
              @click.prevent="$emit('reset')"
              class="has-text-weight-normal"
              tabindex="1">
              <small>Forgot password?</small>
            </a>
          </div>
        </div>
      </template>
      <b-input
        v-model="password"
        type="password"
        autocomplete="current-password" />
    </b-field>
    <b-field grouped>
      <div class="control">
        <button
          @click.prevent="login"
          class="button is-primary"
          :class="{'is-loading': loading}">
          Log in
        </button>
      </div>
      <div class="control">
        <button @click.prevent="$emit('close')" class="button" :disabled="loading">
          Cancel
        </button>
      </div>
    </b-field>
  </form>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import { getValidationError } from '@/services/util';
import API from '@/services/api';

export default {
  name: 'LoginForm',
  data() {
    return {
      email: '',
      password: '',
      loading: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
  },
  mounted() {
    this.$refs.email.focus();
  },
  methods: {
    async login() {
      if (this.loading) return;
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.loading = true;
      try {
        const res = await API.auth.login({
          email: this.email,
          password: this.password,
        });
        this.$store.dispatch('session/getProfile');
        this.$toasted.show(`Welcome back, ${res.data.user.username}!`, { duration: 2000 });
        this.$emit('close');
        this.$gtag.event('login');
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
      this.loading = false;
    },
    getError(field) {
      if (!this.$v[field]) return '';
      if (!this.$v[field].$dirty) return '';
      return getValidationError(this.$v[field]);
    },
  },
};
</script>
