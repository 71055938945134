<template>
  <div class="level" :class="{labelled: labelled}">
    <div class="level-left">
      <b-tooltip
        :label="labelled ? 'Sketch this lesson' : 'Sketch'"
        type="is-dark" position="is-top">
        <router-link :to="lessonURL" class="button">
          <span class="icon">
            <fa-icon :icon="['fas', 'paint-brush']" />
          </span>
          <span v-if="labelled">Sketch this</span>
        </router-link>
      </b-tooltip>
      <b-tooltip
        v-if="loggedIn"
        :label="labelled ? 'Use this sketch as a reference' : 'Remix'"
        type="is-dark" position="is-top">
        <router-link :to="remixURL" class="button">
          <span class="icon">
            <fa-icon :icon="['fas', 'magic']" />
          </span>
          <span v-if="labelled">Remix this</span>
        </router-link>
      </b-tooltip>
      <b-tooltip label="Like" type="is-dark" position="is-top">
        <button class="button" :disabled="!canLike" @click.stop="like">
          <span class="icon">
            <fa-icon :icon="likeIcon" style="color:red;" />
          </span>
          <span v-if="labelled">{{sketch.likes.length}} Likes</span>
          <span v-else>{{sketch.likes.length}}</span>
        </button>
      </b-tooltip>
      <template v-if="expanded">
        <b-tooltip
          :label="labelled ? 'Download PNG image of this sketch' : 'Download'"
          type="is-dark" position="is-top">
          <a class="button" :href="downloadURL" :download="fileName">
            <span class="icon">
              <fa-icon :icon="['fas', 'download']" />
            </span>
            <span v-if="labelled">Download</span>
          </a>
        </b-tooltip>
        <b-tooltip :label="svgDownloadLabel" type="is-dark" position="is-top">
          <a class="button"
            :href="svgURL"
            :download="fileName"
            :disabled="!canDownloadSVG"
            @click="downloadSVG">
            <span class="icon">
              <fa-icon :icon="['fas', 'download']" />
            </span>
            <span v-if="labelled">Download SVG</span>
            <span v-else>SVG</span>
          </a>
        </b-tooltip>
      </template>
    </div>

    <div class="level-right">
      <div v-if="showAdmin" class="mr-3">
        <b-tooltip :label="toggleFeatureLabel" type="is-dark" position="is-top">
          <button class="button" @click.stop="toggleFeature">
            <span class="icon" :style="{color: sketch.isFeatured ? 'gold': ''}">
                <fa-icon :icon="['fas', 'star']" />
              </span>
            <template v-if="labelled">
              <span v-if="sketch.isFeatured">Unfeature</span>
              <span v-else>Feature</span>
            </template>
          </button>
        </b-tooltip>
        <b-tooltip label="Delete" type="is-dark" position="is-top">
          <button class="button is-danger" @click.stop="deleteSketch">
            <span class="icon">
              <fa-icon :icon="['fas', 'trash-alt']" />
            </span>
            <span v-if="labelled">Delete</span>
          </button>
        </b-tooltip>
      </div>
      <b-dropdown aria-role="list" position="is-top-left" v-if="expanded">
        <button class="button mr-4" slot="trigger" @click="onShare">
          <span class="icon">
            <fa-icon :icon="['fas', 'share-alt']" />
          </span>
          <span>Share</span>
        </button>

        <b-dropdown-item
          v-for="(link, i) in socialURLs"
          :key="i"
          aria-role="listitem"
          has-link
          class="social-dropdown">
          <a :href="link.url" target="_blank" rel="nofollow noopener">
            <span class="icon">
              <fa-icon :icon="link.icon" />
            </span>
            {{link.name}}
          </a>
        </b-dropdown-item>
      </b-dropdown>
      <router-link :to="'/sketch/' + sketch.id" class="sketch-link">
        <b-tooltip :label="createdAt" type="is-dark" position="is-top">
          <time :datetime="sketch.createdAt">{{createdAgo}}</time>
        </b-tooltip>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from '@/services/dayjs';
import API from '@/services/api';

export default {
  name: 'Actions',
  props: {
    sketch: {
      type: Object,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    labelled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      loggedIn: 'session/loggedIn',
      user: 'session/user',
      showAdmin: 'site/showAdmin',
    }),
    createdAt() {
      const ca = dayjs(this.sketch.createdAt);
      return ca.format('HH:mm, D MMM \'YY');
    },
    createdAgo() {
      const ca = dayjs(this.sketch.createdAt);
      const m = dayjs();
      return ca.isAfter(m) ? m.fromNow() : ca.fromNow();
    },
    likedByUser() {
      if (!this.loggedIn || !this.user) return false;
      return this.sketch.likes.some((like) => like.userId === this.user.id);
    },
    likeIcon() {
      if (this.likedByUser) return ['fas', 'heart'];
      return ['far', 'heart'];
    },
    canLike() {
      return this.loggedIn && !this.likedByUser && this.sketch.author.id !== this.user.id;
    },
    canDownloadSVG() {
      return this.loggedIn && this.user && this.user.hasPlus;
    },
    svgDownloadLabel() {
      if (!this.canDownloadSVG) return 'SVG Download requires Plus membership';
      return this.labelled ? 'Download SVG image of this sketch' : 'Download SVG';
    },
    fileName() {
      return `${this.sketch.author.username}-${this.sketch.id}.png`;
    },
    downloadURL() {
      return `/api/v2/sketches/download-png/${this.sketch.id}`;
    },
    svgURL() {
      return `/api/v2/sketches/download-svg/${this.sketch.id}`;
    },
    toggleFeatureLabel() {
      return this.sketch.isFeatured ? 'Unfeature' : 'Feature';
    },
    lessonURL() {
      if (!this.loggedIn) return `/signup?id=${this.sketch.lesson.id}`;
      return `/lessons/${this.sketch.lesson.id}`;
    },
    remixURL() {
      if (!this.loggedIn) return this.lessonURL;
      return `/lessons/${this.sketch.lesson.id}?remix=${this.sketch.id}`;
    },
    socialURLs() {
      const directURL = `https://sketchdaily.com/sketches/${this.sketch.id}`; // TODO: fix after v1
      const sketchURL = `https://sketchdaily.com/share/${this.sketch.id}`;
      const tagline = `Check out this cool sketch by ${this.sketch.author.username}!`;
      const hashtags = '#sketchdailyio #sketchdaily #sketch #draw #drawing #digitalart #learningtodraw #learntodraw';
      const tweetText = `${tagline}\n\n${hashtags}`;
      return [
        {
          name: 'Facebook',
          url: `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(sketchURL)}&hashtag=${encodeURIComponent('#sketchdailyio')}`,
          icon: ['fab', 'facebook-f'],
        },
        {
          name: 'Twitter',
          url: `https://twitter.com/intent/tweet/?text=${encodeURIComponent(tweetText)}&url=${encodeURIComponent(sketchURL)}`,
          icon: ['fab', 'twitter'],
        },
        {
          name: 'Email',
          url: `mailto:?subject=${tagline}&body=${directURL}`,
          icon: ['fas', 'envelope'],
        },
      ];
    },
  },
  methods: {
    async like() {
      try {
        const res = await API.sketches.like(this.sketch.id);
        // FIXME: mutating prop
        // eslint-disable-next-line vue/no-mutating-props
        this.sketch.likes = res.data.sketch.likes;
        this.$toasted.show('Sketch liked.', { duration: 500 });
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
    },
    downloadSVG(e) {
      if (!this.canDownloadSVG) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
      return true;
    },
    // admin actions
    async toggleFeature() {
      const action = this.toggleFeatureLabel;
      const c = window.confirm(`${action} this sketch?`); // eslint-disable-line no-alert
      if (!c) return;

      let apiFunc = API.admin.sketches.feature;
      if (this.sketch.isFeatured) apiFunc = API.admin.sketches.unfeature;

      try {
        const res = await apiFunc(this.sketch.id);
        // FIXME: mutating prop
        // eslint-disable-next-line vue/no-mutating-props
        this.sketch.isFeatured = !this.sketch.isFeatured;
        this.$toasted.show(`Sketch ${action.toLowerCase()}d succesfully!`, { duration: 2000 });
        console.log('toggleFeature', res);
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
    },
    async deleteSketch() {
      const c = window.confirm('Delete this sketch?'); // eslint-disable-line no-alert
      if (!c) return;

      try {
        const res = await API.admin.sketches.delete(this.sketch.id);
        this.$toasted.show('Sketch deleted!', { duration: 2000 });
        console.log('delete', res);
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
    },
    onShare(e) {
      this.$gtag.event('share');
      if (window.navigator.share) {
        e.stopPropagation();
        navigator.share({
          title: `${this.sketch.lesson.name} by ${this.sketch.author.username} - SketchDaily`,
          text: `Check out this cool sketch by ${this.sketch.author.username} #sketchdaily #sketchdailyio`,
          url: `https://sketchdaily.com/share/${this.sketch.id}`,
        });
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.sketch-link {
  color: black;

  &:hover {
    text-decoration: underline;
  }
}
.labelled {
  .level-left .button {
    margin-right: 0.5em;
  }
}
.social-dropdown {
  .icon {
    font-size: 1.5em;
    position: relative;
    top: 5px;
    margin-right: 0.25em;
  }
}
</style>
