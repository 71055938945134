<template>
  <div class="columns course-list">
    <div
      v-for="course in courses"
      :key="'course-' + course.id"
      class="column"
      :class="columnClasses">
      <CourseCard :course="course" :userData="userData" />
    </div>
  </div>
</template>

<script>
import CourseCard from '@/components/CourseCard.vue';

export default {
  name: 'CourseList',
  components: {
    CourseCard,
  },
  props: {
    courses: {
      type: Array,
      required: true,
    },
    userData: {
      type: Boolean,
      default: false,
    },
    columnClasses: {
      type: Array,
      default: () => ['is-one-third'],
    },
  },
};
</script>
