<template>
  <div id="app">
    <Navbar/>

    <main class="section">
      <template v-if="!loaded">
        <div class="container is-fullhd">
          <p>Loading...</p>
        </div>
      </template>
      <template v-else>
        <template v-if="requireLogin">
          <Unauthorized />
        </template>
        <template v-else>
          <router-view />
        </template>
      </template>
    </main>

    <footer class="footer">
      <div class="container">
        <div class="content">
          <ins v-if="showAds"
            ref="ads"
            class="adsbygoogle"
            style="display:block;min-height:300px;text-align:center;"
            data-ad-client="ca-pub-3258287986876151"
            data-ad-slot="3107857646"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
          <div class="columns">
            <div class="column">
              <p>
                <small>
                  All user generated content is licensed under
                  <a
                    href="https://tldrlegal.com/license/do-what-the-fuck-you-want-to-but-it's-not-my-fault-public-license-v1-(wtfnmfpl-1.0)"
                    rel="noopener"
                    target="_blank">
                    WTFNMFPL-1.0</a>. Use it as you wish! 🎉
                </small>
              </p>
              <div class="field has-addons social">
                <p class="control">
                  <a href="https://www.twitter.com/sketchdailyio" rel="noopener" target="_blank" class="button is-twitter">
                    <span class="icon">
                      <fa-icon :icon="['fab', 'twitter']" />
                    </span>
                    <span>Twitter</span>
                  </a>
                </p>
                <p class="control">
                  <a href="https://www.instagram.com/sketchdaily.io" rel="noopener" target="_blank" class="button is-instagram">
                    <span class="icon">
                      <fa-icon :icon="['fab', 'instagram']" />
                    </span>
                    <span>Instagram</span>
                  </a>
                </p>
                <p class="control">
                  <a href="https://www.facebook.com/sketchdailyio" rel="noopener" target="_blank" class="button is-facebook">
                    <span class="icon">
                      <fa-icon :icon="['fab', 'facebook']" />
                    </span>
                    <span>Facebook</span>
                  </a>
                </p>
                <p class="control">
                  <a href="https://www.patreon.com/ripexz" rel="noopener" target="_blank" class="button is-patreon">
                    <span class="icon">
                      <fa-icon :icon="['fab', 'patreon']" />
                    </span>
                    <span>Patreon</span>
                  </a>
                </p>
              </div>
              <p><small>
                SketchDaily is powered by a number of
                <router-link to="/open-source">open-source projects</router-link>.
              </small></p>
              <p>
                <small>Site, design and course material &copy;SketchDaily 2017-{{year}}.</small>
                </p>
            </div>
            <div class="column">
              <router-link
                class="navbar-item has-text-weight-semibold"
                to="/admin"
                v-if="isAdmin"
                @contextmenu.native="toggleShowAdmin">
                <span v-if="showAdmin">Admin (On)</span>
                <span v-else>Admin (Off)</span>
              </router-link>
              <router-link class="navbar-item has-text-weight-semibold" to="/about">
                About SketchDaily
              </router-link>
              <a href="/contact" @click="showCrisp" class="navbar-item has-text-weight-semibold">
                Contact Us
              </a>
              <router-link class="navbar-item has-text-weight-semibold" to="/terms">
                Terms &amp; Conditions
              </router-link>
              <router-link class="navbar-item has-text-weight-semibold" to="/privacy">
                Privacy Policy
              </router-link>
              <router-link class="navbar-item has-text-weight-semibold" to="/cookies">
                Cookie Policy
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <Modal />

    <div id="site-notice" v-if="notice.show">
      <span class="content" v-html="notice.content"></span>
      <a class="delete" @click.stop="$store.dispatch('site/hideNotice')" title="Dismiss notice"></a>
    </div>

    <CookieNotice v-if="showCookieNotice" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Navbar from '@/components/Navbar.vue';
import Modal from '@/components/Modal.vue';
import CookieNotice from '@/components/CookieNotice.vue';
import Unauthorized from '@/views/Unauthorized.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    Modal,
    CookieNotice,
    Unauthorized,
  },
  created() {
    window.app = this;
    this.$store.dispatch('session/getProfile');
    this.$store.dispatch('site/init');
  },
  mounted() {
    if (this.showAds && this.$refs.ads && !this.$refs.ads.hasChildNodes()) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  },
  computed: {
    ...mapGetters({
      siteLoaded: 'site/loaded',
      userLoaded: 'session/loaded',
      user: 'session/user',
      loggedIn: 'session/loggedIn',
      notice: 'site/notice',
      isAdmin: 'session/isAdmin',
      showAdmin: 'site/showAdmin',
      showCookieNotice: 'site/showCookieNotice',
    }),
    loaded() {
      return this.siteLoaded && this.userLoaded;
    },
    year() {
      return new Date().getFullYear();
    },
    requireLogin() {
      return this.$route.meta && this.$route.meta.auth && !this.loggedIn;
    },
    showAds() {
      return !this.loggedIn || (this.loggedIn && !this.user.hasPlus);
    },
  },
  watch: {
    loggedIn(loggedIn) {
      if (loggedIn) {
        this.$socket.client.open();
        this.$gtag.config({ user_id: this.user.id });
      } else {
        this.$socket.client.close();
        this.$gtag.config({ user_id: null });
      }
    },
  },
  methods: {
    showCrisp(e) {
      e.preventDefault();
      if (!window.$crisp) {
        this.$router.push('/contact');
        return false;
      }
      window.$crisp.push(['do', 'chat:show']);
      window.$crisp.push(['do', 'chat:open']);
      return false;
    },
    toggleShowAdmin(e) {
      e.preventDefault();
      this.$store.dispatch('site/toggleShowAdmin');
    },
  },
};
</script>

<style lang="scss">
/*! purgecss start ignore */
$primary: #FFB03B;

// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary-invert: findColorInvert($primary);

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

#site-notice {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  height: 2em;
  background: #333;
  color: white;
  z-index: 999;

  .delete {
    position: absolute;
    right: 10px;
    bottom: 5px;
  }
}
canvas[resize] {
  width: 100%;
  height: 100%;
}
.toasted-container.top-right[role=status] {
  top: 80px;
  right: 50px;
}
@media only screen and (max-width: 600px) {
  .toasted-container.top-right[role=status] {
    top: 0;
    right: 0;
  }
}
.article {
  &__body {
    font-size: 1.25em;
  }
  &__content > * {
    margin-bottom: 2em;
  }
}
.footer .social {
  flex-wrap: wrap;
}
/*! purgecss end ignore */
</style>
