<template>
  <div class="sidebar">
    <template v-if="userData">
      <h3 class="subtitle">{{progressTitle}}</h3>
      <xp-chart :xp="userData.last7days"/>
    </template>

    <div class="practice has-text-centered" v-if="currentUser && currentUser.experience > 0">
      <b-tooltip
        label="Draw a random sketch you've previously completed"
        type="is-dark" position="is-bottom"
        v-if="$socket.connected">
        <router-link to="/practice" class="button is-medium">
          <span class="icon">
            <fa-icon :icon="['fas', 'paint-brush']" />
          </span>
          <span>Practice</span>
        </router-link>
      </b-tooltip>
    </div>

    <div class="ads" v-if="showAds">
      <ins class="adsbygoogle"
        ref="ads"
        style="display:block"
        data-ad-client="ca-pub-3258287986876151"
        data-ad-slot="3714857144"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import XPChart from '@/components/XPChart.vue';

export default {
  name: 'Sidebar',
  components: {
    'xp-chart': XPChart,
  },
  props: {
    user: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'session/user',
    }),
    userData() {
      return this.user ? this.user : this.currentUser;
    },
    progressTitle() {
      if (this.user) return `${this.user.username}'s Progress`;
      return 'Progress';
    },
    showAds() {
      return this.currentUser && !this.currentUser.hasPlus;
    },
  },
  mounted() {
    if (this.showAds && this.$refs.ads && !this.$refs.ads.hasChildNodes()) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  },
};
</script>

<style lang="scss" scoped>
.practice {
  margin-top: 2em;
}
</style>
