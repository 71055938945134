import Vue from 'vue';
import Buefy from 'buefy';
import Vuelidate from 'vuelidate';
import VueGtag from 'vue-gtag';
import * as Sentry from '@sentry/browser';
import VueSocketIOExt from 'vue-socket.io-extended';
import Toasted from 'vue-toasted';
import { Vue as VueIntegration } from '@sentry/integrations';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHeart as fasHeart, faCoins, faFire, faBell,
  faPaintBrush, faStar, faCheckCircle, faUsers,
  faDownload, faChevronLeft, faChevronRight,
  faAngleLeft, faAngleRight, faGlobe, faTrashAlt,
  faEdit, faTrophy, faShareAlt, faEnvelope, faMagic,
} from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart, faImage } from '@fortawesome/free-regular-svg-icons';
import {
  faTwitter, faInstagram, faFacebook, faFacebookF,
  faDeviantart, faPatreon,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import socket from '@/services/socket';
import App from './App.vue';
import router from './router';
import store from './store';

if (window.location.host.indexOf('localhost') === -1) {
  Sentry.init({
    dsn: 'https://60388691742a49f6b0a677a0c5f39fe5@o55817.ingest.sentry.io/138282',
    integrations: [new VueIntegration({ Vue, attachProps: true })],
  });
}

library.add(
  fasHeart, farHeart, faCoins, faFire, faBell, faCheckCircle,
  faPaintBrush, faStar, faTwitter, faInstagram, faFacebook,
  faUsers, faDownload, faChevronLeft, faChevronRight, faImage,
  faAngleLeft, faAngleRight, faGlobe, faFacebookF, faDeviantart,
  faTrashAlt, faEdit, faPatreon, faTrophy, faShareAlt, faEnvelope,
  faMagic,
);
Vue.component('fa-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(Buefy);
Vue.use(Toasted);
Vue.use(Vuelidate);
Vue.use(VueGtag, {
  config: { id: 'G-0TSKFMYGBR' },
}, router);

const conn = socket.init();
Vue.use(VueSocketIOExt, conn, { store });

new Vue({
  router,
  store,
  render: (h) => h(App),
  sockets: {
    connect() {
      store.dispatch('socket/onConnect', conn);
    },
  },
}).$mount('#app');
