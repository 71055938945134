import Vue from 'vue';
import API from '@/services/api';

export default {
  namespaced: true,
  state: {
    loaded: {
      version: false,
      notice: false,
      social: false,
    },

    version: 'loading',
    notice: {},
    social: [],
    courses: [],
    showAdmin: false,
    showCookieNotice: false,

    preventNavigation: {
      prevent: false,
      text: null,
    },
  },
  getters: {
    loaded: (state) => Object.values(state.loaded).map((x) => x),
    version: (state) => state.version,
    notice: (state) => state.notice,
    social: (state) => state.social,
    socialById: (state) => (id) => {
      const sc = state.social.filter((s) => s.id === id);
      if (sc.length === 1) return sc[0];
      return null;
    },
    courses: (state) => state.courses,
    course: (state) => (url) => state.courses.filter((c) => c.url === url),
    courseById: (state) => (id) => state.courses.filter((c) => c.id === id),
    showAdmin(state, getters, rootState, rootGetters) {
      return state.showAdmin && rootGetters['session/isAdmin'];
    },
    showCookieNotice: (state) => state.showCookieNotice,
  },
  mutations: {
    loaded(state, prop) {
      state.loaded[prop] = true;
    },
    setVersion(state, version) {
      state.version = version;
    },
    setSocial(state, social) {
      state.social = social;
    },
    setNotice(state, notice) {
      state.notice = notice;
      state.notice.globalShow = notice.show; // cache site setting
    },
    setCourses(state, courses) {
      state.courses = courses;
    },
    hideNotice(state) {
      state.notice.show = false;
    },
    setShowCookieNotice(state, show) {
      state.showCookieNotice = show;
      if (!show) { // save dimissal
        const date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        const cookie = `cn-dismissed=true; path=/; expires=${date.toUTCString()}`;
        document.cookie = cookie;
      }
    },
    setPreventNavigation(state, val) {
      state.preventNavigation = val;
    },
    setShowAdmin(state, showAdmin) {
      state.showAdmin = showAdmin;
      localStorage.setItem('showAdmin', showAdmin);
    },
  },
  actions: {
    init({ commit, dispatch }) {
      dispatch('getVersion');
      dispatch('getNotice');
      dispatch('getSocial');

      try {
        const saRes = localStorage.getItem('showAdmin');
        if (saRes && JSON.parse(saRes) === true) commit('setShowAdmin', true);

        const cookies = document.cookie.split(';');
        const found = cookies.filter((c) => (c.trim() === 'cn-dismissed=true'));
        if (found.length === 0) commit('setShowCookieNotice', true);
      } catch (e) {
        console.error('failed to load showAdmin from LS', e);
      }
    },
    async getVersion({ commit, state, dispatch }) {
      try {
        const res = await API.site.version();
        if (state.version !== 'loading' && state.version !== res.data.version) {
          dispatch('announceVersion');
        }
        commit('setVersion', res.data.version);
      } catch (e) {
        console.error(e);
        Vue.toasted.error('Failed to get version', { duration: 2000 });
      }
      commit('loaded', 'version');
    },
    async getSocial({ commit }) {
      try {
        const res = await API.site.social();
        commit('setSocial', res.data.social);
      } catch (e) {
        console.error(e);
        Vue.toasted.error('Failed to get social sources', { duration: 2000 });
      }
      commit('loaded', 'social');
    },
    async getNotice({ commit }) {
      try {
        const res = await API.site.notice();
        commit('setNotice', res.data.notice);
      } catch (e) {
        console.error(e);
        Vue.toasted.error('Failed to get notice', { duration: 2000 });
      }
      commit('loaded', 'notice');
    },
    async getCourses({ commit }) {
      try {
        const res = await API.courses.list();
        commit('setCourses', res.data.courses);
      } catch (e) {
        console.error(e);
        Vue.toasted.error('Failed to get courses', { duration: 2000 });
      }
    },
    announceVersion() {
      Vue.toasted.info(
        `SketchDaily has just been updated!<br>
        Reload the page as soon as you can to get the latest updates!`,
        {
          action: {
            text: 'Reload',
            onClick: () => window.location.reload(),
          },
        },
      );
    },
    hideNotice({ commit }) {
      commit('hideNotice');
    },
    hideCookieNotice({ commit }) {
      commit('setShowCookieNotice', false);
    },
    preventNavigation({ commit }, text) {
      const data = {
        prevent: false,
        text: null,
      };
      if (text) {
        data.prevent = true;
        data.text = text;
        window.onbeforeunload = (e) => {
          e.returnValue = text;
          return text;
        };
      } else {
        window.onbeforeunload = null;
      }
      commit('setPreventNavigation', data);
    },
    toggleShowAdmin({ commit, state }) {
      const showAdmin = !state.showAdmin;
      commit('setShowAdmin', showAdmin);
    },
  },
};
