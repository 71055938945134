<template>
  <div class="card">
    <div class="card-header">
      <p class="card-header-title">
        <router-link :to="'/sketch/' + sketch.id" class="sketch-link">
          {{sketch.lesson.name}}
        </router-link>
      </p>
    </div>
    <div class="card-image">
      <figure class="image">
        <img :src="sketch.imageUrl" :alt="sketch.lesson.name">
      </figure>
    </div>
    <div class="card-content">
      <Author :sketch="sketch" />

      <div class="content">
        <Actions :sketch="sketch" :expanded="true" />
      </div>
    </div>
  </div>
</template>

<script>
import Author from '@/components/Sketch/Author.vue';
import Actions from '@/components/Sketch/Actions.vue';

export default {
  name: 'ModalSketch',
  components: {
    Author,
    Actions,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sketch() {
      return this.data.sketch;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  min-width: 600px;
}
.sketch-link {
  color: black;

  &:hover {
    text-decoration: underline;
  }
}
</style>
