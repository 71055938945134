<template>
  <div class="unauthorized container is-fullhd">
    <h1 class="title">Please log in</h1>
    <section>
      <p>This page does not quite work for people without an account.</p>
      <p>
        Please <a href="#" @click.prevent="showLogin">log in</a>,
        or <router-link to="/">sign up</router-link> if you haven't already!
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Unauthorized',
  methods: {
    showLogin() {
      this.$store.dispatch('modal/show', { name: 'login' });
    },
  },
};
</script>
