export default {
  namespaced: true,
  state: {
    component: null,
    shown: false,
    data: null,
  },
  getters: {
    shown: (state) => state.shown,
    data: (state) => state.data,
    component: (state) => state.component,
  },
  mutations: {
    show(state, data) {
      state.component = data.name;
      state.data = data.data;
      state.shown = true;
    },
    hide(state) {
      state.shown = false;
      state.component = null;
      state.data = null;
    },
  },
  actions: {
    show({ commit }, data) {
      commit('show', data);
    },
    hide({ commit }) {
      commit('hide');
    },
  },
};
