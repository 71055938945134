<template>
  <div class="gallery container is-fullhd">
    <div class="level">
      <div class="level-left">
        <h1 class="title">
          <template v-if="!username">Gallery</template>
          <template v-else>
            Gallery -
            <router-link :to="`/user/${username}`">{{username}}</router-link>
          </template>
        </h1>
      </div>
      <div class="level-right">
        <b-dropdown
          position="is-bottom-left"
          append-to-body
          aria-role="menu">
          <button
            v-if="loggedIn || username"
            class="button"
            slot="trigger"
            slot-scope="{ active }"
            :class="{'is-active': active}">
            <span>{{filterText}}</span>
          </button>

          <b-dropdown-item has-link aria-role="menuitem">
            <router-link to="/gallery" :class="{'is-active': state == 'all'}">
              By everyone
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item has-link aria-role="menuitem" v-if="loggedIn">
            <router-link
              :to="`/gallery/${user.username}`"
              :class="{'is-active': state == 'current'}">
              By me
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item has-link aria-role="menuitem" v-if="state == 'user'">
            <router-link
              :to="`/gallery/${username}`"
              :class="{'is-active': state == 'user'}">
              By {{username}}
            </router-link>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <section>
      <div class="columns is-multiline">
        <div class="column is-one-quarter" v-for="sketch in sketches" :key="'sketch-' + sketch.id">
          <SketchCard :sketch="sketch" />
        </div>

        <template v-if="loading">
          <div v-for="i in skeletons" :key="'sc-skeleton-' + i" class="column is-one-quarter">
            <SketchCardSkeleton />
          </div>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SketchCard from '@/components/SketchCard.vue';
import SketchCardSkeleton from '@/components/SketchCardSkeleton.vue';
import API from '@/services/api';

const footerOffset = 300;

export default {
  name: 'Gallery',
  components: {
    SketchCard,
    SketchCardSkeleton,
  },
  data() {
    return {
      username: '',
      sketches: [],
      page: 1,
      pages: 1,
      loading: true,
    };
  },
  created() {
    this.username = this.$route.params.username;
    this.load();
  },
  mounted() {
    this.setupAutoLoad();
  },
  computed: {
    ...mapGetters({
      loggedIn: 'session/loggedIn',
      user: 'session/user',
    }),
    skeletons() {
      const mod = this.sketches.length % 4;
      return 20 - mod;
    },
    state() {
      if (!this.username) return 'all';
      if (this.user && this.user.username === this.username) return 'current';
      return 'user';
    },
    filterText() {
      switch (this.state) {
        case 'all':
          return 'By everyone';
        case 'current':
          return 'By me';
        case 'user':
          return `By ${this.username}`;
        default:
          return 'Filter';
      }
    },
  },
  watch: {
    '$route.params.username': function (val) { // eslint-disable-line func-names
      this.username = val;
      this.reset();
    },
  },
  methods: {
    reset() {
      this.loading = true;
      this.sketches = [];
      this.page = 1;
      this.pages = 1;
      this.load();
    },
    async load() {
      try {
        let res;
        if (this.username) {
          res = await API.sketches.listForUser({ page: this.page, username: this.username });
        } else {
          res = await API.sketches.list({ page: this.page });
        }
        this.sketches = this.sketches.concat(res.data.sketches);
        this.pages = parseInt(res.headers['x-pages'], 10) || 1;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
      this.loading = false;
    },
    loadMore() {
      if (this.page >= this.pages) return;
      this.page += 1;
      this.loading = true;
      this.load();
    },
    setupAutoLoad() {
      document.addEventListener('scroll', this.shouldLoadMore);
    },
    shouldLoadMore() {
      if (this.loading) return;
      if (Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)
        + window.innerHeight >= document.documentElement.offsetHeight - footerOffset) {
        this.loadMore();
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.shouldLoadMore);
  },
};
</script>
