/* eslint-disable import/prefer-default-export */
// import { replace, template, templateSettings } from 'lodash';

// templateSettings.interpolate = /{{([\s\S]+?)}}/g;

const TEMPLATES_MAP = {
  email: 'Must be a valid email address',
  alpha: 'Can only contain alphanumeric characters.',
  minLength: 'Must be at least {{min}} characters long.',
  required: 'This field is required.',
  sameAsPassword: 'Does not match the password field.',
};

export function getValidationError(validation) {
  if (!validation.$invalid) return '';

  const errors = Object.keys(validation.$params).reduce(
    (errs, validator) => {
      if (!validation[validator]) {
        let err = TEMPLATES_MAP[validator];
        // console.log('params', validator, validation.$params[validator]);
        Object.keys(validation.$params[validator]).forEach((key) => {
          err = err.replace(`{{${key}}}`, validation.$params[validator][key]);
        });
        errs.push(err);
      }
      return errs;
    },
    [],
  );

  if (errors.length) return errors[0];
  return '';
}
/* eslint-enable import/prefer-default-export */
